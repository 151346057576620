import React from "react";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target[0].value;
    const company = e.target[1].value;
    const email = e.target[2].value;
    const details = e.target[3].value;
    const mailto = `mailto:dhruvasankhe@terra-wise.com?subject=Email%20from%20Contact%20Form&body=Name:%20${encodeURIComponent(
      name
    )}%0ACompany:%20${encodeURIComponent(
      company
    )}%0AEmail:%20${encodeURIComponent(
      email
    )}%0ADetails:%20${encodeURIComponent(details)}`;
    console.log(mailto);
    window.open(mailto);
  };

  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8 lg:pt-14 mx-auto -mt-9">
      <div className="max-w-xl mx-auto">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl ">
            Contact us
          </h1>
          <p className="mt-1 text-gray-600 ">
            We&apos;d love to talk about how we can help you.
          </p>
        </div>
      </div>
      <div className="mt-6 max-w-lg mx-auto">
        <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 ">
          <form onSubmit={handleSubmit}>
            <div className="grid gap-2 lg:gap-2">
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    htmlFor="hs-name-contacts-1"
                    className="block mb-2 text-sm text-gray-700 font-medium "
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="hs-name-contacts-1"
                    id="hs-name-contacts-1"
                    className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-100   "
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    htmlFor="hs-company-contacts-1"
                    className="block mb-2 text-sm text-gray-700 font-medium "
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    name="hs-company-contacts-1"
                    id="hs-company-contacts-1"
                    className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-100   "
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    htmlFor="hs-email-contacts-1"
                    className="block mb-2 text-sm text-gray-700 font-medium"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="hs-email-contacts-1"
                    id="hs-email-contacts-1"
                    autoComplete="email"
                    className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-100   "
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="hs-about-contacts-1"
                  className="block mb-2 text-sm text-gray-700 font-medium "
                >
                  Details
                </label>
                <textarea
                  id="hs-about-contacts-1"
                  name="hs-about-contacts-1"
                  rows="4"
                  className="py-3 px-4 block w-full rounded-lg text-sm focus:border-blue-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-100   "
                ></textarea>
              </div>
            </div>

            <div className="mt-6 grid">
              <button
                type="submit"
                className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white  hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 "
              >
                Send inquiry
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
