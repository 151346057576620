import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./style.css";
import SideDrawer from "./SideDrawer";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGhydXZhc2Fua2hlIiwiYSI6ImNsdHN2YjM2MDB1Y3YyaW52azd4OWttZzUifQ.iCLOjtz2RKSHzMRQtwqK5w";
// will store in .env later
const Map = ({ mapData }) => {
  const mapContainerRef = useRef(null);
  const [clickedMarker, setClickedMarker] = useState(null);
  useEffect(() => {
    if (!mapData) return;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: mapData.features[0].properties.center[0], // random
      zoom: 16,
    });

    // Add navigation control (zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.once("style.load", () => {
      map.addSource("map-data", {
        type: "geojson",
        data: mapData,
      });

      // Add a layer to the map using the GeoJSON data
      map.addLayer({
        id: "map-layer",
        type: "line", // Adjust layer type as needed
        source: "map-data",
        paint: {
          "fill-color": "#0080ff", // Customize fill color
          "fill-opacity": 0.5, // Customize fill opacity
        },
      });
    });
    mapData.features.map((feature) => {
      if (feature.properties.raw_images.length > 0) {
        const markerElement = document.createElement("div");
        if (feature.properties.temperature_difference > 5)
          markerElement.className = "marker-red";
        else markerElement.className = "marker-green";
        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat(feature.properties.center[0])
          .addTo(map);

        marker.getElement().addEventListener("click", () => {
          setClickedMarker(feature.properties);
          console.log("Marker clicked:", feature.properties);
        });
      }
    });

    return () => map.remove();
  }, []);
  const handleCloseDrawer = () => {
    setClickedMarker(null);
  };
  return (
    <>
      <div
        ref={mapContainerRef}
        // className="mt-[3.6rem]"
        className="mt-0"
        style={{ height: "calc(100vh - 4rem)" }}
      />
      {clickedMarker && (
        <SideDrawer onClose={handleCloseDrawer} details={clickedMarker} />
      )}
    </>
  );
};

export default Map;
