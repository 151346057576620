import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";

const CreateClient = () => {
  const [companyname, setCompanyname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (user?.role !== "admin") navigate("/dashboard");
    setToken(user?.token);
  }, [navigate]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "users/register",
        {
          companyName: companyname,
          email,
          password,
          role: isAdmin ? "admin" : "client",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Client created successfully");
      console.log(response?.data);
      setCompanyname("");
      setEmail("");
      setPassword("");
      setIsAdmin(false);
      setError("");
    } catch (error) {
      if (error?.response)
        setError(
          error.response.data.title + ": " + error.response.data.message
        );
      console.error("Creation failed:", error);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{ height: "calc(100vh - 5rem)" }}
    >
      <div className="max-w-md w-full p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold mb-4">Register</h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="companyname"
              className="block text-sm font-medium text-gray-700"
            >
              Company Name
            </label>
            <input
              required
              type="text"
              id="companyname"
              name="companyname"
              value={companyname}
              onChange={(e) => setCompanyname(e.target.value)}
              className="mt-1 p-2 block w-full bg-slate-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              required
              type="email"
              autoComplete="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-2 block w-full bg-slate-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="flex items-center mt-1 gap-x-2">
              <input
                required
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="p-2 block w-full bg-slate-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {passwordVisible ? (
                <div
                  className="px-1 py-2 bg-slate-100 rounded-md shadow-sm font-medium text-gray-700 cursor-pointer"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  Hide
                </div>
              ) : (
                <div
                  className="px-1 py-2 bg-slate-100 rounded-md shadow-sm font-medium text-gray-700 cursor-pointer"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  Show
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <input
              type="checkbox"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
              className="w-4 h-4"
            />
            <label className="block text-sm font-medium text-gray-700">
              Create as Admin
            </label>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateClient;
