import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/TERRAWISE.png";

function Navbar() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showNav, setShowNav] = useState(true);
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      setUser(userInfo);
      setShowNav(true);
    }
    // if (!userInfo) setShowNav(false);
  }, [navigate]);
  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem("userInfo");
    navigate("/");
  };
  const [navColor, setNavColor] = useState("#2C2F2D");

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 450) {
      console.log(scrollTop);
      setNavColor("black");
    } else {
      setNavColor("#2C2F2D");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    showNav && (
      <nav
        className="fixed top-0 left-0 w-full h-16 bg-[#2C2F2D] border-b-[#217EC0] text-white flex justify-between items-center z-10 text-lg border-b-0"
        style={{
          backgroundColor: navColor,
          transition: "background-color 0.5s",
        }}
      >
        <div className="flex items-center gap-6">
          <NavLink to="/">
            <img src={logo} className="h-8 -mt-0.5" alt="logo" />
          </NavLink>
          <NavLink
            to={user ? "/home" : "/"}
            className="navLink"
            activeclassname="active"
          >
            Home
          </NavLink>
          {!user && (
            <NavLink
              to="/products"
              className="navLink"
              activeclassname="active"
            >
              Products
            </NavLink>
          )}
          {user && (
            <NavLink
              to="/dashboard"
              className="navLink"
              activeclassname="active"
            >
              Dashboard
            </NavLink>
          )}
          {user?.role !== "admin" ? (
            <NavLink
              to="/contactus"
              className="navLink"
              activeclassname="active"
            >
              Contact Us
            </NavLink>
          ) : (
            <NavLink
              to="/createclient"
              className="navLink"
              activeclassname="active"
            >
              Create Client
            </NavLink>
          )}
        </div>
        <div className="h-full flex items-center gap-6 bg-[#217EC0] px-6">
          {user ? (
            <button onClick={logoutUser} className="">
              Sign Out
            </button>
          ) : (
            <NavLink to="/signin" className="">
              Sign In
            </NavLink>
          )}
        </div>
      </nav>
    )
  );
}
export default Navbar;
