import axios from "../../axiosConfig";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Map from "../Map/Map";

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [clients, setClients] = useState([]);
  const [currentClient, setCurrentClient] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mapAvailable, setMapAvailable] = useState(false);

  const getAllClients = async (token) => {
    try {
      const response = await axios.get("users/allclients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setClients(response?.data);
      setCurrentClient(response?.data[0]);
      setMapAvailable(response?.data[0].dataId ? true : false);
    } catch (error) {
      console.error("Not Authorised:", error);
    }
  };
  const getGeoJsonData = async (token, email) => {
    try {
      setLoading(true);
      const response = await axios.get("geojson?clientEmail=" + email, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      const { data } = response.data;
      setMapData(data);
    } catch (error) {
      console.error("Not Authorised:", error);
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) setUser(userInfo);
    if (!userInfo) navigate("/signin");
    if (userInfo?.role === "admin") getAllClients(userInfo?.token);
    else {
      setCurrentClient(userInfo);
      setMapAvailable(userInfo.dataId ? true : false);
    }
    // if (userInfo && currentClient)
    //   getGeoJsonData(userInfo?.token, currentClient?.email);
  }, [navigate]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) return;
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      setLoading(true);

      await axios.post(
        "geojson/upload?clientEmail=" + currentClient?.email,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await getGeoJsonData(user?.token, currentClient?.email);
      setSelectedFile(null);
      setLoading(false);
      setMapAvailable(true);
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      {/* <div>
        {clients.length > 0 && (
          <select
            className="border-2 rounded-md border-gray-900"
            onChange={(e) => {
              const result = clients.find(
                (client) => client.companyName === e.target.value
              );
              setCurrentClient(result);
              setMapAvailable(result.dataId ? true : false);
              setMapData(null);
            }}
          >
            {clients.map((client) => (
              <option key={client?._id} value={client?.companyName}>
                {client?.companyName}
              </option>
            ))}
          </select>
        )}
        {!clients.length && (
          <p className="text-lg font-bold">{currentClient?.companyName}</p>
        )}
        <p>Email: {currentClient?.email}</p>
      </div> */}
      {!mapAvailable && !loading && (
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-bold mb-4">Upload GeoJSON File</h2>
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <label
              htmlFor="fileInput"
              className="relative cursor-pointer flex gap-2 bg-blue-500 text-white rounded-lg py-2 px-4 shadow-lg"
            >
              <span className="font-bold">+ Select File</span>
              <input
                id="fileInput"
                type="file"
                className="sr-only"
                onChange={handleFileChange}
              />
            </label>
            {selectedFile && (
              <p className="mt-2">Selected File: {selectedFile.name}</p>
            )}
            <button
              type="submit"
              className="mt-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
            >
              Upload
            </button>
          </form>
        </div>
      )}
      {
        mapAvailable &&
          !mapData &&
          !loading &&
          // (
          //   <div className="flex flex-col items-center">
          //     <button
          //       className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
          //       onClick={() => {
          getGeoJsonData(user?.token, currentClient?.email)
        //     }}
        //   >
        //     Load Map
        //   </button>
        // </div>
        // )
      }
      {loading && (
        <div className="flex justify-center items-center fixed top-1/2 left-1/2">
          <div className="w-16 h-16 border-t-4 border-gray-900 rounded-full animate-spin"></div>
        </div>
      )}
      {mapData && !loading && <Map mapData={mapData} />}
    </>
  );
};

export default Dashboard;
