import React, { useState } from "react";
import SideDrawer from "../SideDrawer/SideDrawer";

const Table = ({ data }) => {
  const statusColors = {
    Pending: "bg-yellow-500 text-white",
    Completed: "bg-green-500 text-white",
    "Not Started": "bg-red-500 text-white",
  };

  const priorityFlags = {
    "Not Set": "text-gray-500",
    Critical: "text-red-500",
    Warning: "text-orange-500",
    Manageable: "text-green-500",
  };
  const [clicked, setClicked] = useState(null);
  const handleCloseDrawer = () => {
    setClicked(null);
  };
  const handleItemClick = (item) => {
    setClicked(item);
  };
  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white ">
            Defects of Plant X
            <p className="mt-1 text-sm font-normal text-gray-500 ">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
              pariatur sed quo eius, beatae iusto eveniet error a maxime
              inventore.
            </p>
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Defect Type
              </th>
              <th scope="col" className="px-6 py-3">
                Assigned
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Priority
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 cursor-pointer bg-white border-b"
                onClick={() => handleItemClick(item)}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {item.name}
                </th>
                <td className="px-6 py-4">{item.defectType}</td>
                <td className="px-6 py-4">{item.assigned}</td>
                <td className="px-6 py-4">
                  <span
                    className={`px-2 py-1 rounded ${statusColors[item.status]}`}
                  >
                    {item.status}
                  </span>
                </td>
                <td className="pl-6 py-4">
                  <span className={`${priorityFlags[item.priority]} font-bold`}>
                    {item.priority}
                  </span>
                </td>
                <td className="pr-6 py-4 text-right">
                  <a
                    href="#"
                    className="font-medium text-blue-600 hover:underline"
                  >
                    Add as a Task
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {console.log("clicked", clicked)}
      {clicked && <SideDrawer onClose={handleCloseDrawer} details={clicked} />}
    </>
  );
};

export default Table;
